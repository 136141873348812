import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import LaunchIcon from "@mui/icons-material/Launch";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function PortfolioBlock({
  image,
  live,
  source,
  title,
  intro,
  techUsed,
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 500, display: "flex", flexDirection: "column" }}>
      <CardMedia
        component="img"
        image={image}
        alt="Project Logo"
        style={{
          height: "300px",
          objectFit: "contain",
          width: "100%",
          padding: "1rem",
        }}
      />
      <CardContent style={{ flexGrow: 1 }}>
        <Typography variant="body2" color="text.primary">
          {intro}
        </Typography>
      </CardContent>
      <Box
        p={"1rem"}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <a
          href={live}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="visit site"
        >
          <LaunchIcon color="action" />
        </a>
        <CardActions disableSpacing>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Typography variant="body2" color="text.primary">
              See Technologies:
            </Typography>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              {" "}
              <ExpandMoreIcon />
            </ExpandMore>
          </Box>
        </CardActions>
      </Box>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Technologies Used:</Typography>
          <Typography paragraph>{techUsed}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
