import React, { useState } from "react";
import PortfolioBlock from "./PortfolioBlock";
import { Box, Grid } from "@mui/material";
import { info } from "../../info/Info";

export default function Portfolio() {
  const [expanded, setExpanded] = useState(
    Array(info.portfolio.length).fill(false)
  );

  const handleExpandClick = (index) => {
    setExpanded((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <Box
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      mr={{ md: 0, xs: "2rem" }}
      ml={{ md: 0, xs: "2rem" }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={2}
        mt={10}
      >
        {info.portfolio.map((project, index) => (
          <Grid
            item
            xs={12}
            md={6}
            key={index}
            display="flex"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <PortfolioBlock
              image={project.image}
              live={project.live}
              source={project.source}
              title={project.title}
              intro={project.intro}
              techUsed={project.tech}
              expanded={expanded[index]}
              onExpandClick={() => handleExpandClick(index)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
