// Images used across the site
import self from "../img/self.png";
import mock1 from "../img/fls-logo.png";
import mock2 from "../img/celerity-logo.png";
import mock3 from "../img/hixon-logo.png";
import mock4 from "../img/orcha-logo.png";
import mock5 from "../img/forrest-robot.jpg";
import mock6 from "../img/donewithit.png";

// Gradient theme color for Site
export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
/*
I highly recommend using a gradient generator to get what you want. 
 */

/*
Information Used Across Site
 */

export const info = {
  firstName: "Forrest",
  lastName: "Alward",
  initials: "fa",
  position: "a Full Stack Developer",
  selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
  baseColor: colors[0],
  miniBio: [
    // these are just some "tidbits" about yourself.
    {
      emoji: "🤓",
      text: "Fellow nerd and lover of all things tech",
    },
    {
      emoji: "🇿🇦",
      text: "Based in South Africa",
    },
    {
      emoji: "💼",
      text: "Co-founder & Director at Saigon Digital",
    },
    {
      emoji: "📧",
      text: "forresalward@gmail.com",
    },
  ],
  socials: [
    {
      link: "https://github.com/forrestalward",
      icon: "fa fa-github",
      label: "github",
    },
    {
      link: "https://www.linkedin.com/in/forrest-alward",
      icon: "fa fa-linkedin",
      label: "linkedin",
    }, // Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
    // Just change the links so that they lead to your social profiles.
  ],
  bio1: "I'm Forrest, a Full Stack Developer who is passionate about creating fast and secure web applications using the Jamstack architecture. I've been working as the Development Director at Saigon Digital and have extensive experience with React. Recently, I've also started exploring React Native to build visually stunning and highly functional mobile applications.",
  bio2: "Throughout my career, I've worked with a range of programming languages and frameworks, including JavaScript, React, Node.js, and more. However, my true passion lies in creating dynamic and performant web applications using the Jamstack approach",
  bio3: "My interest in software development began at a young age, where I found myself editing game source files as a hobby. This interest eventually led me to pursue a career change after obtaining my BSc degree.",
  bio4: "Outside of work, I enjoy indulging in my hobbies, which include paddle sports and gaming. These activities not only provide me with an opportunity to unwind, but also inspire me to think creatively and approach problem-solving in new ways.",
  bio5: "Ultimately, I am driven by my passion to create innovative and functional web and mobile applications that make a positive impact on people's lives. Whether it's a simple application or a complex platform, I believe that the power of the Jamstack can transform digital experiences and drive business success. Let's work together to create something amazing!",
  skills: {
    proficientWith: [
      "Javascript",
      "React",
      "Jamstack Technoligies",
      "Tailwind",
      "Bootstrap",
      "HTML",
      "CSS",
      "SCSS",
      "PHP",
      "NextJS",
      "GatsbyJS",
      "Prismic",
      "WordPress",
      "MaterialUI",
      "Prismic",
      "Strapi",
      "Google Services",
    ],
    exposedTo: ["Vue", "React Native", "Angular", "AWS"],
  },
  hobbies: [
    {
      label: "Polo",
      emoji: "🐎",
    },
    {
      label: "Padel/Tennis",
      emoji: "🎾",
    },
    {
      label: "Gaming",
      emoji: "🎮",
    },
    {
      label: "cooking",
      emoji: "👨🏻‍🍳",
    },
    // Same as above, change the emojis to match / relate to your hobbies or interests.
    // You can also remove the emojis if you'd like, I just think they add some life <3
  ],
  portfolio: [
    // This is where your portfolio projects will be detailed.
    {
      title: "Celerity UK",
      live: "https://celerity-uk.com/",
      image: mock2,
      intro:
        "Celerity, a prominent IT company located in the United Kingdom, we recently partnered with design agency OTM Create to successfully launch their Jamstack site, which was seamlessly integrated with Hubspot and Hotjar analytics. As a result of this collaborative effort, Celerity has been able to establish a strong online presence and enhance their customer engagement through advanced analytics and data-driven insights.",
      tech: "ReactJS, GatsbyJS, GraphQL, Gatsby Cloud, Headless WordPress, Google Analytics, Hotjar integration, Google Lighthouse Perfomance updates and continued support and maintence for dependency updates or Content Editor expierence updates, Hubspot Integration.",
    },
    {
      title: "FLS Group",
      live: "https://fls-group.com/",
      image: mock1,
      intro:
        "Our team partnered with FLS Group, a logistics company, to launch their Jamstack website with a Headless CMS. We included a 3D interactive globe to help them successfully launch their brand. We focused on both visual appeal and user experience, allowing FLS Group to engage effectively with their customers and stakeholders.",
      tech: "ReactJS, GatsbyJS, Headless WordPress, Three JS / WebGL, GraphQL, Gravity Forms",
    },
    {
      title: "Hixon Mortgage",
      live: "https://hixonmortgage.com/",
      image: mock3,
      intro:
        "Lead a team which assisted Hixon Mortgage, a local US-based mortgage company, in launching their high-performing Jamstack Headless Solution. By integrating the site with WordPress, we were able to provide a seamless content editing experience. The result was a visually appealing and user-friendly website that effectively communicated the company's brand message, mission, and values to its clients.",
      tech: "ReactJS, GatsbyJS, GraphQL, Gatsby Cloud, Headless WordPress, Google Analytics, Google Lighthouse Perfomance updates and continued support and maintence for dependency updates or Content Editor expierence updates",
    },
    {
      title: "Orcha Health",
      live: "https://orchahealth.com/",
      image: mock4,
      intro:
        "Orcha Health is a UK-based company founded by NHS clinicians with a mission to empower individuals with safe and accessible digital health solutions. Our team has been working closely with the Orcha Health marketing team for over a year, providing support and maintenance services to their website as the company continues to grow and expand.",
      tech: "PHP, Custom Theme Devlopment, Hubspot Integration, Geo-redirect Logic, MySQL, Apache, DeployHQ",
    },
    {
      title: "Forrest Chat GPT Robot",
      live: "https://forrest-chat-gpt.netlify.app/",
      image: mock5,
      intro:
        "Introducing Forrest's Robot: a streamlined, free version of ChatGPT powered by OpenAI API, designed to make AI-driven conversations accessible to everyone. Developed using Netlify and Render, Forrest's Robot provides an intuitive platform for users to experience AI chat capabilities without breaking the bank. Please be aware that the Render free plan may require a brief boot-up time. Embark on a journey with Forrest's Robot, showcasing the future of AI communication and celebrating the spirit of open access.",
      tech: "Forrest's Robot, an accessible AI chat experience, harnesses OpenAI API, Netlify, Render, Vite, and JavaScript to deliver engaging conversations for users.",
    },
    {
      title: "Done With It (React Native)",
      live: "https://github.com/forrestalward/DoneWithIt.git",
      image: mock6,
      intro:
        "Meet Done With It: a work-in-progress project where I'm diving in and getting my hands dirty with React Native. As I explore the world of mobile app development, I'm eager to share my journey and learning experiences. Check out the progress and follow the adventure on GitHub as I continue to build and refine this exciting project.",
      tech: "Employing React Native, Expo, React, React Native Web, and Material UI, 'Done With It' provides a comprehensive and seamless mobile app development experience for developers.",
    },
  ],
};
